import React from 'react'
import './CSS/footer.css';
import { FaYoutubeSquare, FaTwitterSquare, FaInstagramSquare, FaTiktok } from 'react-icons/fa'
import { ScrollToTop } from '../ScrollToTop';


export const Footer = () => {
  return (
    <div className="main-footer">
      <div className="footer-container">
        <div className="row">
          <div className="footer-col">
            <h3>ABOUT US</h3>
            <ul>
              {/* <a target="_blank" rel="norefferer" href="https://docs.google.com/document/d/1LpJVPZrFK9Bwhyzfw0G4R5AyMENtrJLxS1qtM1PJ7m0/edit?usp=sharing"><li>Gang Guidelines</li></a>
              <a target="_blank" rel="norefferer" href="https://docs.google.com/document/d/1nxk6fd_4_-NneB93CzZRtbcSB_6cjEJP3hlHN_D3pIw/edit?usp=sharing"><li>Community Guidelines</li></a>
              <a target="_blank" rel="norefferer" href=""><li>Business Guidelines</li></a> */}
              <a target="_blank" rel="norefferer" href="https://discord.gg/svrp"><li>Discord</li></a>
            </ul>
          </div>
          <div className="footer-col">
            <h3>SHOP</h3>
            <ul>
              <a target="_blank" rel="norefferer" href="https://storyverse.tebex.io/category/packages"><li>VIP Packages</li></a>
              <a target="_blank" rel="norefferer" href=""><li>Vehicles</li></a>
              <a target="_blank" rel="norefferer" href="https://storyverse.tebex.io/category/priority-queue"><li>Queue Priorities</li></a>
              <a target="_blank" rel="norefferer" href=""><li>Custom Items</li></a>
            </ul>
          </div>
          <div className="footer-col">
            <h3>FOLLOW US</h3>
            <div className="social-links">
              <a target="_blank" rel="norefferer" href='https://www.youtube.com/channel/UCF3K1SSXu0lIgjRtOIojeUg'><FaYoutubeSquare className='footer-icon' /></a>
              <a target="_blank" rel="norefferer" href='https://www.tiktok.com/@storyverserp'><FaTiktok className='footer-icon' /></a>
            </div>
          </div>
        </div>
        <ScrollToTop />
        <div className="signature">
          <p>©{new Date().getFullYear()} StoryVerse, All Rights Reserved.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer;