const StreamerData = [
  {
    streamer: 'Leimenstriker',
    streamerlink: 'leimenstriker'
  },
  {
    streamer: 'SwaggyDrKush',
    streamerlink: 'swaggydrkush'
  },
  {
    streamer: 'Clappersonn',
    streamerlink: 'clappersonn'
  },
  {
    streamer: 'SpagbolChomper',
    streamerlink: 'spagbolchomper'
  },
  // {
  //   streamer: 'ShayneFactorOG',
  //   streamerlink: 'shaynefactorog'
  // }
]


export default StreamerData